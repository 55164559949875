import commonEN from "~/locales/en/common";
import commonFR from "~/locales/fr/common";

export const i18n = {
  supportedLngs: ["en", "fr"],
  fallbackLng: "en",
  defaultNS: "common",
  resources: {
    en: { common: commonEN },
    fr: { common: commonFR },
  },
};
