export default {
  "brand.name": "marque",
  "model.brand.name": "marque",
  "model.series.name": "série",
  "series.name": "série",
  "model.referenceNumber": "numéro de référence",
  "provider.name": "source",
  "condition": "condition",
  "country": "pays",
  "papersAvailable": "papiers disponibles",
  "releaseYear": "année",
  "createdAt.desc": "plus récent en premier",
  "createdAt.asc": "plus ancien en premier",
  "price.asc": "moins cher en premier",
  "price.desc": "plus cher en premier",
};
