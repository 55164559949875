export default {
  "brand.name": "brand",
  "model.brand.name": "brand",
  "model.series.name": "series",
  "series.name": "series",
  "model.referenceNumber": "reference number",
  "provider.name": "source",
  "condition": "condition",
  "country": "country",
  "papersAvailable": "papers available",
  "releaseYear": "year",
  "createdAt.desc": "newest first",
  "createdAt.asc": "oldest first",
  "price.asc": "cheap first",
  "price.desc": "expensive first",
};
